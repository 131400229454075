export const IssueStates = {
  New: 0,
  InProgress: 1,
  Solved: 2,
  Agreed: 3,
  Closed: 4,
  Disagreed: 5,
  Reopened: 6,
  Ready: 7,
  Denied: 8,
  NotAgreedWith: 9,
  Awaiting: 10,
  Scheduled: 11,
  Forwarded: 12,
  Dispute: 13,
  Answered: 14,
  Processed: 15
}

export const IssueStateLabels = {
  0: 'Nieuw',
  1: 'In behandeling',
  2: 'Opgelost',
  3: 'Akkoord',
  4: 'Gesloten',
  5: 'Niet akkoord',
  6: 'Heropend',
  7: 'Gereed',
  8: 'Afgewezen',
  9: 'Niet mee eens',
  10: 'In afwachting',
  11: 'Ingepland',
  12: 'Doorgestuurd',
  13: 'Geschil',
  14: 'Beantwoord',
  15: 'Behandeld'
}

export const openIssueStates = [
  IssueStates.New,
  IssueStates.InProgress,
  IssueStates.Disagreed,
  IssueStates.Reopened,
  IssueStates.NotAgreedWith,
  IssueStates.Awaiting,
  IssueStates.Scheduled,
  IssueStates.Forwarded,
  IssueStates.Dispute,
  IssueStates.Answered
]

export const inProgressIssueStates = [
  IssueStates.InProgress,
  IssueStates.Awaiting,
  IssueStates.Scheduled,
  IssueStates.Forwarded,
  IssueStates.Reopened
]

export const closedIssueStates = [
  IssueStates.Solved,
  IssueStates.Agreed,
  IssueStates.Closed,
  IssueStates.Ready,
  IssueStates.Denied,
  IssueStates.Processed
]

export const issueTypes:{ [key: number]: string } = {
  1: 'Servicemelding',
  2: 'Bezwaar',
  3: 'Vraag',
  4: 'Oplevering',
  5: 'Keuring',
  6: 'Voorschouw'
}
